import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useContext } from 'react';
import { VendersContext } from '../../context/Store';
import { Island } from 'mdi-material-ui';


const Brands = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  let { isLang, setIsLang } = useContext(VendersContext);

  const [products, setProducts] = useState([]);

  async function getBrands(page) {
    try {
      const headers = {
        token: localStorage.getItem('userToken')
      };

      const response = await axios.get(`https://api.multi-choice.org/api/dashboard/brands?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.brands);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    }
  }

  async function searchBrands(e, page) {
    if (e.target.value) {

      let { data } = await axios.get(`https://api.multi-choice.org/api/dashboard/tables_search/brands/${e.target.value}?page=${page}`, {
        headers: {
          token: localStorage.getItem('userToken')


        }
      })
      setProducts(data.data.brands);
      //console.log(data);
    }
    else {
      getBrands();
    }

  }

  useEffect(() => {


    getBrands(1);
  }, []);




  let emptyProduct = {
    name: ''

  };


  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nameTouched, setNameTouched] = useState(false);
  const [nameArTouched, setNameArTouched] = useState(false);
  const [nameDeTouched, setNameDeTouched] = useState(false);
  const [idTouched, setIdTouched] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);
  const [isAddingBrand, setIsAddingBrand] = useState(false);



  const openNew = () => {
    setIsAddingBrand(true);
    setProduct(emptyProduct);
    setProductDialog(true);
  };


  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };


  const onSave = async (product) => {
    try {
      const response = await axios.post(`https://api.multi-choice.org/api/dashboard/brands/update`, product, {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('userToken')
        }
      });
      const data = response.data;
      // handle response data
      getBrands(currentPage); // Fetch brands for the current page
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Brand updated successfully', life: 3000 });
    } catch (error) {
      // handle error
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update brand', life: 3000 });
    }
  };
  const editProduct = (product) => {
    setProduct({ id: product.id, name: product.name });
    setIsAddingBrand(false);
    setProductDialog(true);
  };


  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };


  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteBrandDialoge = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      // Create a payload object with the product ID
      const payload = {
        brand_id: product.id
      };

      // Send a DELETE request to the API endpoint with the payload in the request body and the Authorization header with the token value
      await axios.delete('https://api.multi-choice.org/api/dashboard/brands/delete', {
        headers: {
          token: localStorage.getItem('userToken')
        },
        data: payload
      });

      // Update the products list state to remove the product that was deleted
      const updatedProducts = products.filter((val) => val.id !== product.id);
      setProducts(updatedProducts);

      // Reset the product state
      setProduct(emptyProduct);

      // Hide the delete product dialog
      setDeleteProductDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Brand Deleted', life: 3000 });
    } catch (error) {
      //console.log(error.response);
      // Show an error toast message
      setDeleteProductDialog(false);

      toast.current.show({ severity: 'error', summary: 'Error', detail: 'This brand is related to some products', life: 4000 });
    }
  };
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    try {
      const payload = {
        brand_id: product.id
      };

      // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
      const deletePromises = selectedProducts.map((product) => {
        return axios.delete(`https://api.multi-choice.org/api/dashboard/brands/delete`, {
          headers: {
            token: localStorage.getItem('userToken')
          },
          data: payload
        });
      });
      await Promise.all(deletePromises);

      // Update the products list state to remove the deleted products
      const updatedProducts = products.filter((val) => !selectedProducts.includes(val));
      setProducts(updatedProducts);

      // Reset the selected products state and hide the delete products dialog
      setSelectedProducts(null);
      setDeleteProductsDialog(false);

      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    } catch (error) {
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete products', life: 3000 });
    }
  };




  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);

    if (name === 'name') {
      setNameTouched(true);
    }
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);

    if (name === 'id') {
      setIdTouched(true);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='d-flex gap-4'>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
      </div>
    );
  };

  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{isLang === "en" ? "Manage Brands" : "إدارة العلامات التجارية"}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText onChange={searchBrands} type="search" onInput={(e) => searchBrands(e.target.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />
      </span>
    </div>
  );
  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        <Button label={isLang === "en" ? "Add Brand" : "اضافة ماركة"} icon="pi pi-plus" severity="success" onClick={openNew} />        <Button
          label={isLang === "en" ? "Delete" : " حذف"}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          className={selectedProducts && selectedProducts.length ? '' : 'p-disabled'}
        />
      </div>
    );
  };

  const saveBrand = async (brand) => {
    try {
      const response = await fetch('https://api.multi-choice.org/api/dashboard/brands/create', {
        method: 'POST',
        headers: {
          token: localStorage.getItem('userToken'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(brand),
      });
      const data = await response.json();
      // handle response data
      getBrands()
      setProducts([...products, data]); // add new brand to products state
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Brand added successfully', life: 3000 });
    } catch (error) {
      // handle error
      //console.log(error);
      // Show an error toast message
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to add brand', life: 3000 });
    }
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };
  const handleSaveClick = () => {
    if (isAddingBrand) {
      saveBrand(product);
    } else {
      onSave(product);
    }
    setIsAddingBrand(false);
    setProduct(emptyProduct);
    setProductDialog(false);
  };
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = (event) => {
    const newPage = event.page + 1; // Assuming the API pagination starts from 1
    setCurrentPage(newPage);
    getBrands(newPage);
    setFirst(event.first);
    setRows(event.rows);
  };
  const productDialogFooter = (
    <div>
      <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={handleSaveClick} />
      <Button label={isLang === "en" ? "Cancel" : "إلغاء "} icon="pi pi-times" onClick={hideDialog} className="p-button-secondary" />
    </div>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"}
        icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );

  return (

    <div>
      <Toast ref={toast} />
      <div className="card ">
        <Toolbar dir={isLang == "en" ? 'ltr' : 'rtl'} className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable dir={isLang == "en" ? 'ltr' : 'rtl'} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" globalFilter={globalFilter} header={header}>
          <Column selectionMode="multiple" className='px-3' exportable={true}></Column>
          <Column field="id" header={isLang === "en" ? "ID" : "الرقم"} style={{ minWidth: '12rem' }} ></Column>
          <Column field="name" header={isLang === "en" ? "Brand Name" : "العلامة التجارية"} style={{ minWidth: '16rem' }} ></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <Paginator ref={dtt.current} first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} currentPage={currentPage - 1} />
      </div>



      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={productDialog} style={{ width: '34rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Add Brand " : "إضافة ماركة"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
        <div className="field">
          <label htmlFor="name" className="font-bold">
            {isLang === "en" ? "Name" : " الاسم"}
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, 'name')}
            required
            className={classNames({ 'p-invalid': submitted && !product.name })}
          />
          {submitted && !product.name && (
            <small className="p-error">Name is required.</small>
          )}
        </div>

      </Dialog>



      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Confirm" : "تأكيد"} modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
        <div className="confirmation-content ">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && (
            <span>
              {isLang === "en" ? "Are you sure you want to delete" : " هل أنت متأكد أنك تريد حذف"}  <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteProductsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang === "en" ? "Confirm" : "تأكيد"} modal footer={deleteProductsDialogFooter} onHide={hideDeleteBrandDialoge}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && <span> {isLang === "en" ? "Are you sure you want to delete the selected products?" : "   هل أنت متأكد أنك تريد حذف المنتجات المحددة؟"}</span>}
        </div>
      </Dialog>
    </div>


  );
}

export default (Brands);