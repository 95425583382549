import React from 'react'
import { Button } from 'react-bootstrap'

export default function Pixels() {
  return (
    <div>


      <label htmlFor="faq_text_en" className='h6 mt-3'>Google Analytics: </label>
      <textarea rows="3"


        className="form-control form-control-lg"
        id="faq_text_en"
        name="faq_text_en"
      />


      <label htmlFor="faq_text_de" className='h6 mt-3'>Facebook Pixel</label>
      <textarea rows="3"


        className="form-control form-control-lg"
        id="faq_text_de"
        name="faq_text_de"
      />




      <label htmlFor="faq_text_ar" className='h6 mt-3'>Ads</label>
      <textarea rows="3"


        className="form-control form-control-lg"
        id="faq_text_ar"
        name="faq_text_ar"
      />
          <button type="submit" class="btn btn-primary mt-4">Submit</button>

    </div>
  )
}
