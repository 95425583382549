import React, { useContext } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import Roles from './Roles';
import SubAdmins from './SubAdmins';
import Test from '../Inventory/Test';
import { VendersContext } from '../../context/Store';

export default function Admins() {
    let { isLang, setIsLang } = useContext(VendersContext);

    return (
        <TabView className='card rounded-4 p-4'>

            <TabPanel header={isLang === "en" ? "Roles" : "المدراء"}>
                <Roles />

            </TabPanel>
            <TabPanel header={isLang === "en" ? "Sub-Admins" : "مجموعات المدراء"}>
                <SubAdmins />

            </TabPanel>

        </TabView>
    )
}
