import React, { useState, useEffect, useContext } from "react";
import { Chart } from "primereact/chart";
import axios from "axios";
import CountUp from "react-countup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ChartComponent from "../Charts/Charts";
import LineChart from "../Charts/Charts";
import { Link } from "react-router-dom";
import { VendersContext } from "../../context/Store";

export default function Dashboard() {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [chartData3, setChartData3] = useState({});
  const [chartOptions3, setChartOptions3] = useState({});
  const [statistics, setStatistics] = useState({});
  const [statisticsTable, setStatisticsTable] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const fetchData = () => {
    axios
      .get("https://api.multi-choice.org/api/dashboard/statistics", {
        headers: {
          token: localStorage.getItem("userToken")
        }
      })
      .then(response => {
        //console.log(response.data.data);
        setStatistics(response.data.data);
        setStatisticsTable(response.data.data.last_products);
        const safeCount = response.data.data.safe_count;
        const unsafeCount = response.data.data.unsafe_count;
        const undefinedCount = response.data.data.undefined_count;

        const data = {
          labels: ["Safe", "Unsafe", "Undefined"],
          datasets: [
            {
              data: [safeCount, unsafeCount, undefinedCount],
              backgroundColor: ["#2ECC71", "#E74C3C", "#F1C40F"],
              hoverBackgroundColor: ["#2ECC71", "#E74C3C", "#F1C40F"]
            }
          ]
        };

        const options = {
          plugins: {
            legend: {
              labels: {
                usePointStyle: true
              }
            }
          }
        };

        setChartData2(data);
        setChartOptions2(options);
      })
      .catch(error => {
        //console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          type: "bar",
          label: "Dataset 1",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          data: [50, 25, 12, 48, 90, 76, 42]
        },
        {
          type: "bar",
          label: "Dataset 2",
          backgroundColor: documentStyle.getPropertyValue("--green-500"),
          data: [21, 84, 24, 75, 37, 65, 34]
        },
        {
          type: "bar",
          label: "Dataset 3",
          backgroundColor: documentStyle.getPropertyValue("--yellow-500"),
          data: [41, 52, 24, 74, 23, 21, 32]
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };

    setChartData3(data);
    setChartOptions3(options);
  }, []);

  //---------------------------------------------------------
  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});

  useEffect(() => {
    fetchData();
  }, []);
  //----------------------------
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data1 = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "First Dataset",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4
        },
        {
          label: "Second Dataset",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          tension: 0.4
        }
      ]
    };
    const options1 = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };

    setChartData(data1);
    setChartOptions(options1);
  }, []);

  const statusBodyTemplate = rowData => {
    let colorClass, label, disabled;

    if (rowData.safe === 2) {
      colorClass = "p-button-danger";
      label = "unSafe";
      disabled = true;
    } else if (rowData.safe === 1) {
      colorClass = "p-button-success";
      label = "safe";
      disabled = false;
    } else {
      return null;
    }

    return <Button label={label} className={`p-button-rounded ${colorClass} ststus-btn`} disabled={disabled} />;
  };

  return (
    <>
      <div dir={isLang == "en" ? "ltr" : "rtl"} className="d-flex flex-row gap-4 mb-4">
        <div className=" grid d-flex justify-content-around dashboard-box shadow w-100 rounded-4 ">
          <i className="pi pi-users icon-dashboard-color" style={{ fontSize: "3rem" }} />
          <div className="media-body text-right d-flex flex-column">
            <CountUp start={0} end={statistics.users_count} duration={4} separator="," className="mb-1 h2" />
            <span className="h5"> {isLang === "en" ? "Users" : "العملاء"} </span>
          </div>
        </div>
        <div className=" grid d-flex justify-content-around dashboard-box shadow w-100 rounded-4 ">
          <i className="pi pi-database icon-dashboard-color" style={{ fontSize: "3rem" }} />
          <div className="media-body text-right d-flex flex-column">
            <CountUp start={0} end={statistics.products_count} duration={4} separator="," className="mb-1 h2" />
            <span className="h5 "> {isLang === "en" ? "Products" : "المنتجات"} </span>
          </div>
        </div>
        <div className=" grid d-flex justify-content-around dashboard-box shadow w-100 rounded-4 ">
          <i className="pi pi-inbox icon-dashboard-color" style={{ fontSize: "3rem" }} />
          <div className="media-body text-right d-flex flex-column">
            <CountUp start={0} end={statistics.suggested_count} duration={4} separator="," className="mb-1 h2" />
            <span className="h5">{isLang === "en" ? "Suggested Products " : "المنتجات المقترحة"} </span>
          </div>
        </div>
        <div className=" grid d-flex justify-content-around dashboard-box shadow w-100 rounded-4 ">
          <i className="pi pi-mobile icon-dashboard-color" style={{ fontSize: "3rem" }} />
          <div className="media-body text-right d-flex flex-column">
            <CountUp start={0} end={statistics.products_scanned} duration={4} separator="," className="mb-1 h2" />
            <span className="h5"> {isLang === "en" ? "Scanned Products " : "المنتجات "} </span>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4  flex-row">
        <div className="card w-75 h-50">
          <LineChart />
        </div>
        <div className="card w-25 pt-5 ">
          <h5 className="p-3 mb-5 text-center">{isLang === "en" ? "Products Status Analysis " : " تصنيف حالة المنتجات"}</h5>
          <div>
            <Chart type="pie" data={chartData2} options={chartOptions2} className="w-full md:w-30rem" />
          </div>
        </div>
      </div>

      <div className="pb-4 d-flex gap-2">
        <div className="card flex justify-content-center w-100 pb-2 mt-4">
          <h5 className=" p-3 align-items-center"> {isLang === "en" ? "Last 10 Products" : "المنتجات المفحوصة مؤخراً"}</h5>
          <DataTable value={statisticsTable}>
            <Column
              className="text-center"
              field="name"
              header={isLang === "en" ? "Name" : "الاسم"}
              body={rowData => (
                <Link to={`/edit-product/${rowData.id}`}>{rowData.name !== null ? rowData.name : rowData.code}</Link>
              )}
            />
            <Column className="text-center" field="code" header={isLang === "en" ? "Code" : "الكود"} />
            <Column
              className="text-center"
              field="safe"
              body={statusBodyTemplate}
              header={isLang === "en" ? "Status" : "الحالة"}
            />
          </DataTable>{" "}
        </div>
      </div>
    </>
  );
}
