import Vendor from '../Layout/Vendor';
import Navber from './../Components/Navber/Navber';
import Error from './../Components/NotFound/Error';
import Sildebar from './../Components/Sidebar/Sildebar';
import Auth from './../Layout/Auth';
import Contact from './../Pages/Contact/Contact';
import Dashboard from './../Pages/Dashbord/Dashbord';
import Login from './../Pages/auth/Login/Login';
import Home from './../Pages/Home/Home';

const Component = {
    // Layout 
    Vendor,
    Auth,
    Dashboard,
    Contact,
    Login,
    Navber,
    Sildebar,
    Error,

    // Pages Right click 
    Home
}

export default Component


