import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import CountUp from 'react-countup';
import { VendersContext } from '../../context/Store';
import { Link } from 'react-router-dom';

const ProductsReport = () => {
    let { isLang, setIsLang } = useContext(VendersContext);

    const [products, setProducts] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterValue, setFilterValue] = useState(null);


    const dtt = useRef(null);


    const handleFilterChange = (e) => {
        const newFilterValue = e.target.value;
        
        setFilterValue(newFilterValue);
        setPage(1);
        
        fetchData(1, newFilterValue);
      };
    const filteredProducts = filterValue ? products.filter((product) => product.safe === filterValue) : products;
    useEffect(() => {
        fetchData(page, filterValue);
      }, [page, filterValue]);

      const fetchData = (pageNumber, filter) => {
        let url = `https://api.multi-choice.org/api/dashboard/reports/products?page=${pageNumber}`;
      
        if (startDate && endDate) {
          const formattedStartDate = formatDate(startDate);
          const formattedEndDate = formatDate(endDate);
      
          url = `https://api.multi-choice.org/api/dashboard/reports/products?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${pageNumber}`;
        }
      
        if (filter) {
          url += `&safe=${filter}`;
        }

        axios
            .get(url, {
                headers: {
                    token: localStorage.getItem('userToken'),
                },
            })
            .then((response) => {
                setProducts(response?.data?.data.products);
                setTotalRecords(response?.data.data.total);
            })
            .catch((error) => {
                //console.log(error);
            });
    };


    // const formatDate = (date) => {
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear().toString().slice(-2);

    //     return `${day}/${month}/${year}`;
    // }
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };
    const [currentPage, setCurrentPage] = useState(1);
    const onPageChange = (event) => {
      const newPage = event.page + 1; 
      setCurrentPage(newPage);
      fetchData(newPage);
      setFirst(event.first);
      setRows(event.rows);
      setPage(event.page + 1);

    };

    const statusBodyTemplate = (rowData) => {
        let colorClass, label, disabled;

        if (rowData.safe === "2") {
            colorClass = 'p-button-danger';
            label = 'unSafe';
            disabled = true;
        } else if (rowData.safe === "1") {
            colorClass = 'p-button-success';
            label = 'safe';
            disabled = false;
        } else {
            return null;
        }

        return (
            <Button label={label} className={`p-button-rounded ${colorClass} ststus-btn`} disabled={disabled} />);
    };
    const handleSearch = () => {
        fetchData();
    };
    return (
        <>
            <div className="card p-4 mb-3 d-flex flex-row justify-content-between">
                <div className='d-flex flex-row align-items-center gap-3'>

                    <h6>{isLang === "en" ? "From:" : "من:"}</h6>
                    <Calendar dir={isLang == "en" ? 'ltr' : 'ltr'} placeholder={isLang === "en" ? "enter start date" : "تاريخ البداية"} value={startDate} onChange={(e) => setStartDate(e.value)} showIcon iconPos='left' />
                </div>
                <div className='d-flex flex-row align-items-center gap-3'>

                    <h6>{isLang === "en" ? "To:" : "إلى:"}</h6>
                    <Calendar dir={isLang == "en" ? 'ltr' : 'ltr'} placeholder={isLang === "en" ? "enter end date" : "تاريخ النهاية"} value={endDate} onChange={(e) => setEndDate(e.value)} showIcon iconPos='left' />
                </div>
                <Button label={isLang === "en" ? "Search" : "البحث"} onClick={handleSearch} />

                <div className='d-flex align-items-center '>

                    <h5 className='p-2 '>{isLang === "en" ? "Total Products:" : "إجمالي المنتجات"}</h5>
                    <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h5" />
                </div>
            </div>


            <DataTable value={filteredProducts} rows={rows} first={first} >
            <Column className="text-center" field="name" header={isLang === "en" ? "Name" : "المنتج"} body={(rowData) => (
                    <Link
                        to={`/edit-product/${rowData.id}`}
                    >
                {rowData.name !== null ? rowData.name : rowData.code}
                    </Link>
                )} />                <Column className="text-center" field="code" header={isLang === "en" ? "Code" : "الكود"} />
                <Column className="text-center justify-content-center align-items-center" header={
                    <>
                        <div className='d-flex align-items-center text-center gap-2 justify-content-center'>

                            <label className=' text-center'>
                                {isLang === "en" ? "Status" : "الحالة"}
                            </label><select className='form-select select-status text-center' value={filterValue} onChange={handleFilterChange}>
                                <option value="">{isLang === "en" ? "All" : "الكل"}</option>
                                <option value="1">{isLang === "en" ? "Safe" : "امن"}</option>
                                <option value="2">{isLang === "en" ? "UnSafe" : "غير امن"}</option>
                            </select>
                        </div>
                    </>
                } body={statusBodyTemplate} />
                <Column
                    className="text-center"
                    field="user.name"
                    header={isLang === "en" ? "Added By" : "بواسطة"}
                    body={(rowData) => {
                        if (!rowData?.user?.name) {
                            return 'No name';
                        }
                        return rowData?.user?.name;
                    }}
                />
                <Column
                    className="text-center"
                    field="created_at"
                    header={isLang === "en" ? "Added Date" : "تاريخ الإضافة"}
                    body={(rowData) => formatDate(new Date(rowData.created_at))}
                />
            </DataTable>

            <Paginator ref={dtt.current} first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} currentPage={currentPage - 1} />
        </>
    );
};

export default ProductsReport;
