import React, { useContext, useState } from 'react';
import Localization from '../Contact/Localization';
import { VendersContext } from '../../context/Store';
import { Table } from 'react-bootstrap';

const Home = () => {
    let { isLang } = useContext(VendersContext);

    return (
        <>
            <Table responsive={true} id='my-table' className='rounded-3 '>
                {/* <thead>
                    <tr className='text-center  ' style={{ background: '#F9F9F9' }}>
                        {
                            Localization[isLang]?.TableHeader?.map((item, index) => (
                                <th key={index}>{item}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody className='text-center'>
                    <tr  >
                        <td > 
                            shazly 
                        </td>
                        <td > 
                            shazly 
                        </td>
                        <td >
                            shazly
                        </td>
                        <td >
                            shazly
                        </td>
                    </tr>

                </tbody> */}

            </Table>
        </>
    );
};

export default Home;
