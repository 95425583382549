import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link, useLocation } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { Image } from 'primereact/image';
import CountUp from 'react-countup';
import { VendersContext } from '../../context/Store';


const Products = (props) => {
  let { isLang, setIsLang } = useContext(VendersContext);


  const [products, setProducts] = useState([]);


  async function getProducts(page) {
    try {
      const langChange = localStorage.getItem('langChange');
      const headers = {
        token: localStorage.getItem('userToken'),
        lang: langChange || 'en' // set default language to 'en' if langChange is not set
      };

      // add support for additional languages
      if (langChange === 'de') {
        headers.lang = 'de';
      } else if (langChange === 'ar') {
        headers.lang = 'ar';
      }

      const response = await axios.get(`https://api.multi-choice.org/api/dashboard/products/get_suggested_products?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.products);
      //console.log(data.data.products);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    }
  }
  async function searchProducts(e, page) {
    if (e.target.value) {

      let { data } = await axios.get(`https://api.multi-choice.org/api/dashboard/tables_search/products/${e.target.value}?page=${page}`, {
        headers: {
          token: localStorage.getItem('userToken')


        }
      })
      setProducts(data.data.products);
      //console.log(data);
    }
    else {
      getProducts();
    }

  }


  function onPageChange(event) {
    getProducts(event.page + 1);
  }
  function onSelectionChange(event) {
    setSelectedProducts(event.value);
  }

  useEffect(() => {
    getProducts(1)


  }, []);

  let emptyProduct = {
    id: null,
    name: '',
  };


  const [productDialog, setProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);
  const dtt = useRef(null);



  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };



  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
      } else {
        _product.id = createId();
        _product.image = 'product-placeholder.svg';
        _products.push(_product);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const viewSuggested = (product) => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/view-suggested/${product.id}`);
  };



  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = '';
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };







  const onInputChange = (e, name) => {
    let val = e.target.value;
    // Check if val is empty
    if (!val) {
      val = 'undefined';
    }
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };




  const imageBodyTemplate = (rowData) => {
    const image = rowData.images.find((img) => img.type === "front");
    return image ? (
      <div className=" flex justify-content-center border-round">
        <Image src={image.image}
          alt={rowData.name}
          width='90px' height='100px' preview />
      </div>) : (
      <span>No image</span>
    );
  };



  const addedByBodyTemplate = (rowData) => {
    if (rowData.is_admin === true) {
      return "admin";
    } else if (rowData.is_user === true) {
      return "user";
    } else {
      return "Scanned Product ";
    }
  };
  const statusBodyTemplate = (rowData) => {
    let colorClass = rowData.safe === "1" ? 'p-button-success ststus-btn' : 'p-button-danger ststus-btn';
    let label = rowData.safe === "1" ? 'Safe' : 'UnSafe';

    return (
      <Button label={label} className={`p-button-rounded ${colorClass}`} />
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex gap-4'>
          <Button icon="pi pi-eye" rounded outlined className="mr-2" onClick={() => viewSuggested(rowData)} />
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className='d-flex align-items-center '>

        <h3 className='p-2 '> {isLang === "en" ? "Total Suggested Products:" : "إجمالي المنتجات المقترحة:"}</h3>
        <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h3" />
      </div>      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText onChange={searchProducts} type="search" onInput={(e) => searchProducts(e.target.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />
      </span>
    </div>
  );


  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  const { name = 'undefined' } = product;

  return (

    <div>
      <Toast ref={toast} />
      <div className="card ">


        <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={onSelectionChange}
          dataKey="id" globalFilter={globalFilter} header={header}>
          <Column selectionMode="multiple" className='px-3 ' exportable={true}></Column>
          <Column field="code" header={isLang === "en" ? "Code" : "الكود"} style={{ minWidth: '12rem' }}></Column>
          <Column
            field="name"
            header={isLang === "en" ? "Name" : "المنتج"}
            className='px-3 test'
            style={{ minWidth: '16rem' }}
          >
            {product.name}
          </Column>

          <Column field="user.name" header={isLang === "en" ? "User" : "العميل"}></Column>
          <Column field="image" header={isLang === "en" ? "Image" : "الصورة"} body={imageBodyTemplate}></Column>
          <Column field="status" header={isLang === "en" ? "Status" : "الحالة"} body={statusBodyTemplate} className='px-3' style={{ minWidth: '10rem' }} />
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <Paginator ref={dtt.current} totalRecords={totalRecords} rows={20} onPageChange={onPageChange}></Paginator>
      </div>


      <Dialog visible={productDialog} style={{ width: '34rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Product Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
        {product.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`} alt={product.image} className="product-image block m-auto pb-3" />}
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} />
          {submitted && !product.name && <small className="p-error">Name is required.</small>}
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
        </div>



      </Dialog>

      <Dialog style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal  >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && <span>Are you sure you want to delete the selected products?</span>}
        </div>
      </Dialog>
    </div>


  );
}

export default (Products);