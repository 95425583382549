import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Img from '../../../assets/Images/icon-sm.png';
import LogoSvg from '../../../assets/svg/LogoSvg';
import "./login.scss";
import { Alert, CircularProgress } from '@mui/material';
import { validateLoginForm } from '../Services/Validation';
import { handelLogin } from '../Services/AuthService';


const Login = () => {
    const [loademail, setLoadEmail] = useState(false);
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    let navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validateLoginForm,
        onSubmit: (values) => handelLogin(values, setLoadEmail, navigate)
    });


    return (
        <>
            <div className="app__login">
                <Container fluid >
                    <Row className='d-flex justify-content-center align-content-center '>
                        <Col xl={6} lg={6} xd={6} sm={12} className='vh-100'>
                            <div className='app__login-left  vh-100   '>
                                <div className="app__login-left bg__login shadow">
                                    <img loading="lazy" src={Img} height="120" width="120px" className='bg__login-icons' alt="Login page background" />

                                    <div className="login__form_inputs  " >
                                        <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                                        <p className="text-muted text-center">Sign in to continue to Bugfree.</p>
                                        <form onSubmit={formik.handleSubmit} className='login__form'  >
                                            <div className="input_form ">
                                                <label htmlFor="email" >Email</label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    placeholder="Email"
                                                    className={`  py-2 form-control border-0   `} />
                                                {formik.errors.email && formik.touched.email ?
                                                    <Alert severity="error" sx={{ paddingTop: '0', paddingBottom: '0', marginTop: '10px' }}>{formik.errors.email}</Alert> : null
                                                }

                                            </div>
                                            <div className="input_form">
                                                <label htmlFor="password">Password</label>
                                                <div className="password_open">
                                                    <input
                                                        id="password"
                                                        name="password"
                                                        type={handelOpenPassword}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        className={`py-2 form-control border-0`}
                                                        placeholder={'Enter your password'}
                                                    />
                                                    {handelOpenPassword === "password" ? (
                                                        <LogoSvg.PasswordHidden
                                                            onClick={() => setHandelOpenPassword("text")}
                                                            className="app__login-btn-icons"
                                                        />
                                                    ) : (
                                                        <LogoSvg.PasswordOpen
                                                            onClick={() => setHandelOpenPassword("password")}
                                                            className="app__login-btn-icons"
                                                        />
                                                    )}
                                                </div>
                                                {formik.errors.password && formik.touched.password ? (
                                                    <Alert severity="error" sx={{ paddingTop: '0', paddingBottom: '0', margin: '0' }}>
                                                        {formik.errors.password}
                                                    </Alert>
                                                ) : null}
                                            </div>

                                            <div className='w-100  d-flex align-items-center justify-content-center'>
                                                <button disabled={!(formik.isValid && formik.dirty)} className={`${!(formik.isValid && formik.dirty) ? 'app__login-btn opisity ' : 'app__login-btn opisity1'} mt-3 `} type='submit'>
                                                    {loademail ?
                                                        <CircularProgress
                                                            sx={{
                                                                color: '#ffff',
                                                            }}
                                                            size={18} className={'customProgress'} />
                                                        :
                                                        <LogoSvg.ArrowRight className='app__login-btn-icons ' />
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Login
