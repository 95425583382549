import { Toaster } from 'react-hot-toast';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Component from './constants/Component';
import VenderContext from './context/Store';
import './style/App.scss';
import Brands from './Pages/Inventory/Brands';
import Categories from './Pages/Inventory/Categories';
import Products from './Pages/Inventory/Products';
import AddProduct from './Pages/Inventory/AddProduct';
import SuggestedProducts from './Pages/Inventory/SuggestedProducts';
import Stores from './Pages/Inventory/Stores';
import Settings from './Pages/Settings/Settings';
import Users from './Pages/Users/Users';
import Test from './Pages/Inventory/Test';
import EditProduct from './Pages/Inventory/EditProduct';
import ViewSuggested from './Pages/Inventory/ViewSuggested';
import ViewUser from './Pages/Users/ViewUser';
import Admins from './Pages/Admins/Admins';
import StaticPage from './Pages/Inventory/StaticPage';
import SubAdmins from './Pages/Admins/SubAdmins';
import Complaints from './Pages/Complaints/Complaints';
import Reviews from './Pages/Reviews/Reviews';
import Keywords from './Pages/Insects/Keywords';
import Profile from './Pages/Profile/Profile';
import UsersReport from './Pages/Reports/UsersReport';
import ProductsReports from './Pages/Reports/ProductsReports';
import ScannedReport from './Pages/Reports/ScannedReport';

function App() {

  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('userToken')
    if (!token) {
      return <Navigate to="/admin/login" replace={true} />
    } else {
      return children;
    }
  }
  const root = createHashRouter([

    {
      path: '', element: <Component.Vendor />, children: [
        { index: true, element: <ProtectedRoutes><Component.Dashboard /></ProtectedRoutes> },
        { path: '/contact', element: <ProtectedRoutes><Component.Contact /></ProtectedRoutes> },
        { path: '/home', element: <ProtectedRoutes> <Component.Home /> </ProtectedRoutes> },
        { path: '/brands', element: <ProtectedRoutes> <Brands /> </ProtectedRoutes> },
        { path: '/ststic-page', element: <ProtectedRoutes> <StaticPage /> </ProtectedRoutes> },
        { path: '/complaints', element: <ProtectedRoutes> <Complaints /> </ProtectedRoutes> },
        { path: '/categories', element: <ProtectedRoutes> <Categories /> </ProtectedRoutes> },
        { path: '/stores', element: <ProtectedRoutes> <Stores /> </ProtectedRoutes> },
        { path: '/reviews', element: <ProtectedRoutes> <Reviews /> </ProtectedRoutes> },
        { path: '/products', element: <ProtectedRoutes> <Products /> </ProtectedRoutes> },
        { path: '/edit-product/:id', element: <ProtectedRoutes><EditProduct /> </ProtectedRoutes> },
        { path: '/view-suggested/:id', element: <ProtectedRoutes> <ViewSuggested /> </ProtectedRoutes> },
        { path: '/addProduct', element: <ProtectedRoutes> <AddProduct /> </ProtectedRoutes> },
        { path: '/settings', element: <ProtectedRoutes>  <Settings />  </ProtectedRoutes> },
        { path: '/admins', element: <ProtectedRoutes>  <Admins />  </ProtectedRoutes> },
        { path: '/users', element: <ProtectedRoutes> <Users /> </ProtectedRoutes> },
        { path: '/admin-profile', element: <ProtectedRoutes> <Profile /> </ProtectedRoutes> },
        { path: '/insects-keywords', element: <ProtectedRoutes> <Keywords /> </ProtectedRoutes> },
        { path: '/reports-users', element: <ProtectedRoutes> <UsersReport /> </ProtectedRoutes> },
        { path: '/reports-products', element: <ProtectedRoutes> <ProductsReports /> </ProtectedRoutes> },
        { path: '/reports-scanned', element: <ProtectedRoutes> <ScannedReport /> </ProtectedRoutes> },
        { path: '/view-user/:id', element: <ProtectedRoutes> <ViewUser /> </ProtectedRoutes> },
        { path: '/test', element: <Test /> },
        { path: '/suggested-products', element: <ProtectedRoutes>  <SuggestedProducts /> </ProtectedRoutes> },
        {
          path: '*', element: <Component.Error />
        }
      ],
    },

    {
      path: '/admin/', element: <Component.Auth />, children: [
        { path: 'login', element: <Component.Login /> },
      ]
    },


  ])
  return (
    <div>

      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            // background: '#000',
            // color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            background: '#fff',
            color: '#000',
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />

      <VenderContext>
        <RouterProvider router={root} />
      </VenderContext>


    </div>
  );
}

export default App;