import axios from "axios";
import { useContext, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useEffect } from "react";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";

export default function ComplaintsMessages() {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [showDialog, setShowDialog] = useState(false);
  const options = ["Off", "On"];
  const [buttonValue, setButtonValue] = useState(options[0]);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const [editingMessage, setEditingMessage] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const toast = useRef(null);

  const handleSubmit = () => {
    const data = {
      has_description: buttonValue === "On" ? 1 : 0,
      message_en: nameEn,
      message_ar: nameAr,
      message_de: nameDe
    };

    axios
      .post("https://api.multi-choice.org/api/dashboard/complaint_messages/create", data, {
        headers: {
          token: localStorage.getItem("userToken")
        }
      })
      .then(response => {
        // Handle success
        //console.log(response.data);
        // Reset input fields
        toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully", life: 3000 });

        setShowDialog(false); // Close the dialog
        fetchMessages();
      })
      .catch(error => {
        // Handle error
        console.error(error);
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add", life: 3000 });
      });
  };

  const handleUpdate = async () => {
    try {
      const updatedMessage = {
        id: editingMessage.id,
        has_description: editingMessage.has_description,
        message_en: nameEn,
        message_de: nameDe,
        message_ar: nameAr
      };

      const response = await axios.post(
        "https://api.multi-choice.org/api/dashboard/complaint_messages/update",
        updatedMessage,
        {
          headers: {
            token: localStorage.getItem("userToken")
          }
        }
      );

      toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully", life: 3000 });

      setEditingMessage(null); 
      fetchMessages(); 
    } catch (error) {
      console.error(error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update", life: 3000 });
    }
  };

  const handleEditDialogOpen = message => {
    setEditingMessage(message);
    setShowEditDialog(true);

    // Set initial values of input fields
    setNameEn(message.translations.find(t => t.locale === "en").message);
    setNameDe(message.translations.find(t => t.locale === "de").message);
    setNameAr(message.translations.find(t => t.locale === "ar").message);
  };
  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "has_description") {
      value = e.target.checked ? 1 : 0;
    }
    setEditingMessage(prevEditingMessage => ({
      ...prevEditingMessage,
      [field]: value
    }));
  };

  const fetchMessages = async page => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`https://api.multi-choice.org/api/dashboard/complaint_messages?page=${page}`, {
        headers: {
          token: token,
          lang: selectedLanguage
        }
      });
      const { messages, total } = response.data.data;
      setMessages(messages);
      setTotalPages(Math.ceil(total / 10)); // Assuming 10 messages per page
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditDialogClose = () => {
    setShowEditDialog(false);
    setEditingMessage(null);
  };
  const handleButtonChange = e => {
    setButtonValue(e.value);
  };

  const handleButtonClick = () => {
    handleSubmit();
  };
  //console.log(editingMessage)
  useEffect(
    () => {
      fetchMessages(currentPage);
    },
    [currentPage, selectedLanguage]
  );

  const handlePagination = page => {
    setCurrentPage(page);
  };
  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDelete = async messageId => {
    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    if (confirmDelete) {
      try {
        await axios.delete("https://api.multi-choice.org/api/dashboard/complaint_messages/delete", {
          headers: {
            token: localStorage.getItem("userToken"),
            "Content-Type": "application/json"
          },
          data: {
            message_id: messageId
          }
        });
        toast.current.show({ severity: "success", detail: "Deleted Successfully", life: 3000 });

        fetchMessages();
      } catch (error) {
        console.error("Error deleting message:", error);
        toast.current.show({ severity: "error", detail: "Failed to delete this message", life: 3000 });
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={showDialog}
        onHide={handleDialogClose}
        style={{ width: "35rem", height: "380px" }}
        header={isLang === "en" ? "Add New Complaint Message" : "إضافة رسالة شكوى جديدة"}
      >
        <div>
          <TabView>
            <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_en" className="font-bold input-panels-complaints">
                {isLang === "en" ? "EN_Name" : "الاسم"}
              </h6>
              <InputText id="name_en" className="w-100" required onChange={e => setNameEn(e.target.value)} />
            </TabPanel>
            <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_ar" className="font-bold input-panels-complaints">
                {isLang === "en" ? "AR_Name" : " الاسم"}
              </h6>
              <InputText className="w-100" id="name_ar" required onChange={e => setNameAr(e.target.value)} />
            </TabPanel>
            <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_de" className="font-bold input-panels-complaints">
                {isLang === "en" ? "DE_Name" : "الاسم "}
              </h6>
              <InputText className="w-100" id="name_de" required onChange={e => setNameDe(e.target.value)} />
            </TabPanel>
          </TabView>
          <div className="justify-content-between d-flex">
            <h6> {isLang === "en" ? "Allow user to write a description here" : "السماح للمستخدم بكتابة وصف للشكوى"}</h6>
            <SelectButton value={buttonValue} onChange={handleButtonChange} options={options} />
          </div>

          <Button label="Save" onClick={handleButtonClick} />
        </div>
      </Dialog>
      <Dialog
        visible={showEditDialog}
        onHide={handleEditDialogClose}
        style={{ width: "37rem", height: "420px" }}
        header="Edit Message"
      >
        {editingMessage && (
          <div>
            <TabView>
              <TabPanel header="EN Name" className="d-flex gap-4">
                <h6 htmlFor="name_en" className="edit-label-complaints font-bold input-panels-complaints">
                  Message EN:
                </h6>
                <InputText className="w-100" id="name_en" value={nameEn} onChange={e => setNameEn(e.target.value)} />
              </TabPanel>
              <TabPanel header="DE Name" className="d-flex gap-4">
                <h6 htmlFor="name_de" className="edit-label-complaints font-bold input-panels-complaints">
                  Message DE:
                </h6>
                <InputText id="name_de" className="w-100" value={nameDe} onChange={e => setNameDe(e.target.value)} />
              </TabPanel>
              <TabPanel header="AR Name" className="d-flex gap-4">
                <h6 htmlFor="name_ar" className="edit-label-complaints font-bold input-panels-complaints">
                  Message AR:
                </h6>
                <InputText id="name_ar" className="w-100" value={nameAr} onChange={e => setNameAr(e.target.value)} />
              </TabPanel>
            </TabView>

            <div className="d-flex gap-4 p-3">
              <h6 htmlFor="name_en" className="edit-label-complaints font-bold input-panels-complaints">
                Has Description:
              </h6>
              <div>
                <input
                  type="checkbox"
                  checked={editingMessage.has_description === 1}
                  onChange={e => handleInputChange(e, "has_description")}
                />
                <label>Checkbox Label</label>
              </div>
            </div>
            <div className="d-flex gap-4 pt-3">
              <Button
                label="Save"
                onClick={() => {
                  handleUpdate();
                  handleEditDialogClose();
                }}
              />
              <Button label="Cancel" onClick={handleEditDialogClose} />
            </div>
          </div>
        )}
      </Dialog>
      <>
        <div className="card rounded-4 p-3">
          <div className="flex mb-2 d-flex px-2  bg-light flex-wrap gap-2 align-items-center justify-content-between">
            <Button
              label={isLang === "en" ? "Add Message" : "إضافة رسالة"}
              onClick={handleDialogOpen}
              icon="pi pi-plus"
              className="p-button-success"
            />
          </div>
          <div>
            <table class="table pt-2">
              <thead class="table-light">
                <tr>
                  <th scope="col">{isLang === "en" ? "ID" : "الرقم"}</th>
                  <th scope="col">{isLang === "en" ? "Message" : "الرسالة"}</th>
                  <th scope="col">{isLang === "en" ? "Has Description" : "وصف الرسالة"}</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="text-center">
                {messages.map(message => (
                  <tr className="role-tr" key={message.id}>
                    <td>{message.id}</td>
                    {editingMessage && editingMessage.id === message.id ? (
                      <td>
                        <InputText
                          value={editingMessage.message}
                          onChange={e => setEditingMessage({ ...editingMessage, message: e.target.value })}
                        />
                      </td>
                    ) : (
                      <td>{message.message}</td>
                    )}
                    <td>{message.has_description === 1 ? "Yes" : "No"}</td>
                    <td>
                      <Button
                        icon="pi pi-pencil"
                        rounded
                        outlined
                        className="mr-2"
                        onClick={() => handleEditDialogOpen(message)}
                      />
                      <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(message.id)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                <button key={page} onClick={() => handlePagination(page)}>
                  {page}
                </button>
              ))}
            </div>
          </div>
        </div>
      </>
    </>
  );
}
