import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import Dropzone from "react-dropzone";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Image } from "primereact/image";
import Joi from "joi";
import { Alert, CircularProgress } from "@mui/material";

import { VendersContext } from "../../context/Store";

const ProductForm = () => {
  let { isLang, setIsLang } = useContext(VendersContext);
  const [storesData, setStoresData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [insectsData, setinsectsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const fileToast = useRef(null);

  const toast = useRef(null);

  const [formData, setFormData] = useState({
    name_en: "",
    name_de: "",
    name_ar: "",
    code: "",
    generic_name_en: "",
    generic_name_de: "",
    generic_name_ar: "",
    ingredients_text_en: "",
    ingredients_text_de: "",
    ingredients_text_ar: "",
    quantity: "",
    safe: "",
    brands: [],
    stores: [],
    insects: [],
    categories: [],
    packaging_en: "",
    packaging_de: "",
    packaging_ar: "",
    allergens_en: "",
    allergens_de: "",
    allergens_ar: "",
    is_user: "8",
    ranking: null,
    front: null,
    ingredients: null,
    nutrition_facts: null,
    packaging: null,
    more_interesting: null
  });

  //validation
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    code: Joi.string()
      .required()
      .label("code"),
    name_en: Joi.string()
      .required()
      .label("Name_EN"),
    name_de: Joi.string()
      .required()
      .label("Name_De"),
    name_ar: Joi.string()
      .required()
      .label("Name_AR")
  });

  // get the product details data
  async function getProductsData() {
    try {
      const langChange = localStorage.getItem("langChange");
      const headers = {
        token: localStorage.getItem("userToken"),
        lang: langChange || "en" // set default language to 'en' if langChange is not set
      };

      // add support for additional languages
      if (langChange === "de") {
        headers.lang = "de";
      } else if (langChange === "ar") {
        headers.lang = "ar";
      }

      const response = await axios.get("https://api.multi-choice.org/api/dashboard/products/product_data", { headers });
      const data = response.data;

      setStoresData(data.data.stores);
      setCategoriesData(data.data.categories);
      //console.log(data.data.categories);
      setinsectsData(data.data.insects);
      setBrandsData(data.data.brands);
    } catch (error) {
      console.error(error);
    }
  }

  const [status, setStatus] = useState("");

  useEffect(() => {
    getProductsData();
  }, []);

  const handleRankingChange = e => {
    const value = e.target.checked ? 1 : null;
    setFormData(prevFormData => ({
      ...prevFormData,
      ranking: value
    }));
  };

  const handleInputChange = (event, field) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    // Perform validation for the specific field
    const { error } = schema.validate({ [field]: value }, { abortEarly: false });
    const updatedErrors = { ...errors, [field]: error ? error.details[0].message : null };
    setErrors(updatedErrors);
    //console.log(updatedErrors);
  };

  const navigate = useNavigate();

  const handleSubmit = event => {
    event.preventDefault();

    // Perform validation for all fields
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const updatedErrors = {};
      error.details.forEach(detail => {
        const field = detail.path[0];
        updatedErrors[field] = detail.message;
      });
      setErrors(updatedErrors);
      // return; // Stop the submission if there are errors
    }

    // Map the status value to its ID
    const statusIdMap = {
      undefined: 0,
      safe: 1,
      unsafe: 2
    };

    const updatedFormData = {
      ...formData,
      brands: formData.brands.map(brand => brand.id),
      stores: formData.stores.map(store => store.id),
      insects: formData.insects.map(insect => insect.id),
      categories: formData.categories.map(category => category.id),
      safe: statusIdMap[status], // Use the mapped status ID
      ranking: formData.ranking, // Include the updated ranking value
      front: formData.front,
      ingredients: formData.ingredients,
      nutrition_facts: formData.nutrition_facts,
      packaging: formData.packaging,
      more_interesting: formData.more_interesting
    };

    axios
      .post("https://api.multi-choice.org/api/dashboard/products/create", updatedFormData, {
        headers: {
          token: localStorage.getItem("userToken"),
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        toast.current.show({ severity: "success", summary: "Success", detail: "The Product Added successfully!", life: 3000 });

        navigate("/products");
        //console.log(res);
      })
      .catch(err => {
        // Failure
        toast.current.show({ severity: "error", summary: "Error", detail: "The Form Update failed", life: 3000 });

        console.error(err);
      });
  };
  <Button onClick={() => handleSubmit()} className="p-button-rounded p-button-secondary" label="Submit" />;

  const handleStatusChange = event => {
    setStatus(event.target.value);
  };
  // dropzone function
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setselectedFiles(files);
  }

  // Add a function to handle image updates
  const handleImageUpdate = type => event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageData = {
        type: type,
        image: reader.result
      };
      // Update the formData with the new image data
      setFormData(prevFormData => ({
        ...prevFormData,
        images: prevFormData.images.map(image => (image.type === type ? imageData : image))
      }));
    };
    reader.readAsDataURL(file);
  };
  const handleFrontImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      front: file
    });
  };

  const handleIngredientsImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      ingredients: file
    });
  };

  const handleNutritionFactsImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      nutrition_facts: file
    });
  };

  const handlePackagingImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      packaging: file
    });
  };

  const handleMoreInterestingImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      more_interesting: file
    });
  };
  const onUpload = () => {
    fileToast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded" });
  };

  return (
    <form onSubmit={handleSubmit} className="p-5 d-flex gap-4">
      <Toast ref={toast} />

      <div className="col-md-9">
        <TabView className="card p-2 ">
          <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"}>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="name_en"> {isLang === "en" ? "EN_Name:" : "الاسم:"}</label>
                <InputText
                  className="w-100"
                  name="name_en"
                  id="name_en"
                  onChange={e => setFormData({ ...formData, name_en: e.target.value })}
                  value={formData.name_en}
                />
                {errors.name_en && (
                  <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                    {errors.name_en}
                  </Alert>
                )}
              </div>
              <div className="w-100">
                <label htmlFor="generic_name_en">{isLang === "en" ? "Generic Name EN:" : "الاسم الشائع:"}</label>
                <InputText
                  className="w-100"
                  name="generic_name_en"
                  id="generic_name_en"
                  onChange={e => setFormData({ ...formData, generic_name_en: e.target.value })}
                  value={formData.generic_name_en}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="allergens_en">{isLang === "en" ? "Allergens:" : " مسببات الحساسية:"}</label>
                <InputText
                  className="w-100"
                  name="allergens_en"
                  id="allergens_en"
                  onChange={e => setFormData({ ...formData, allergens_en: e.target.value })}
                  value={formData.allergens_en}
                />
              </div>

              <div className="w-100">
                <label htmlFor="packaging_en">{isLang === "en" ? "packaging:" : "  التغليف:"}</label>
                <InputText
                  className="w-100"
                  name="packaging_en"
                  id="packaging_en"
                  onChange={e => setFormData({ ...formData, packaging_en: e.target.value })}
                  value={formData.packaging_en}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="ingredients_text_en"> {isLang === "en" ? "ingredients_text_en" : "المكونات:"}</label>
                <textarea
                  className="w-100"
                  name="ingredients_text_en"
                  id="ingredients_text_en"
                  onChange={e => handleInputChange(e, "ingredients_text_en")}
                  value={formData.ingredients_text_en}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"}>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="name_en"> {isLang === "en" ? "Name_De: " : "الاسم:"}</label>
                <InputText
                  className="w-100"
                  name="name_de"
                  id="name_de"
                  onChange={e => setFormData({ ...formData, name_de: e.target.value })}
                  value={formData.name_de}
                />
                {errors.name_de && (
                  <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                    {errors.name_de}
                  </Alert>
                )}
              </div>
              <div className="w-100">
                <label htmlFor="generic_name_de"> {isLang === "en" ? "Generic Name DE" : "الاسم الشائع:"}</label>
                <InputText
                  className="w-100"
                  name="generic_name_de"
                  id="generic_name_de"
                  onChange={e => setFormData({ ...formData, generic_name_de: e.target.value })}
                  value={formData.generic_name_de}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="allergens_de"> {isLang === "en" ? "Allergens DE" : "مسببات الحساسية:"}</label>
                <InputText
                  className="w-100"
                  name="allergens_de"
                  id="allergens_de"
                  onChange={e => setFormData({ ...formData, allergens_de: e.target.value })}
                  value={formData.allergens_de}
                />
              </div>

              <div className="w-100">
                <label htmlFor="packaging_de"> {isLang === "en" ? "packaging" : "التغليف:"}</label>
                <InputText
                  className="w-100"
                  name="packaging_de"
                  id="packaging_de"
                  onChange={e => setFormData({ ...formData, packaging_de: e.target.value })}
                  value={formData.packaging_de}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="ingredients_text_en">{isLang === "en" ? "ingredients_text_de" : "المكونات:"}</label>
                <textarea
                  className="w-100"
                  name="ingredients_text_de"
                  id="ingredients_text_de"
                  onChange={e => setFormData({ ...formData, ingredients_text_de: e.target.value })}
                  value={formData.ingredients_text_de}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"}>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="name_ar">{isLang === "en" ? "AR_Name" : " الاسم:"}</label>
                <InputText
                  className="w-100"
                  name="name_ar"
                  id="name_ar"
                  onChange={e => setFormData({ ...formData, name_ar: e.target.value })}
                  value={formData.name_ar}
                />
                {errors.name_ar && (
                  <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                    {errors.name_ar}
                  </Alert>
                )}
              </div>
              <div className="w-100">
                <label htmlFor="generic_name_ar">{isLang === "en" ? "Generic Name AR" : "الاسم الشائع:"}</label>
                <InputText
                  className="w-100"
                  name="generic_name_ar"
                  id="generic_name_ar"
                  onChange={e => setFormData({ ...formData, generic_name_ar: e.target.value })}
                  value={formData.generic_name_ar}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="allergens_ar">{isLang === "en" ? "Allergens AR" : "مسببات الحساسية:"}</label>
                <InputText
                  className="w-100"
                  name="allergens_ar"
                  id="allergens_ar"
                  onChange={e => setFormData({ ...formData, allergens_ar: e.target.value })}
                  value={formData.allergens_ar}
                />
              </div>

              <div className="w-100">
                <label htmlFor="packaging_ar">{isLang === "en" ? "packaging" : " التغليف:"}</label>
                <InputText
                  className="w-100"
                  name="packaging_ar"
                  id="packaging_ar"
                  onChange={e => setFormData({ ...formData, packaging_ar: e.target.value })}
                  value={formData.packaging_ar}
                />
              </div>
            </div>
            <div className="d-flex gap-4 mb-3">
              <div className="w-100">
                <label htmlFor="ingredients_text_ar">{isLang === "en" ? "ingredients_text_AR" : "المكونات:"}</label>
                <textarea
                  className="w-100"
                  name="ingredients_text_ar"
                  id="ingredients_text_ar"
                  onChange={e => setFormData({ ...formData, ingredients_text_ar: e.target.value })}
                  value={formData.ingredients_text_ar}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>

        <div className="d-flex gap-4 mb-3 mt-3">
          <div className="w-100">
            <label htmlFor="code"> {isLang === "en" ? "Product Code" : " كود المنتج:"}</label>
            <InputText
              className="w-100"
              name="code"
              id="code"
              onChange={e => setFormData({ ...formData, code: e.target.value })}
              value={formData.code}
            />
            {errors.code && (
              <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                {errors.code}
              </Alert>
            )}
          </div>

          <div className="w-100">
            <label htmlFor="categories">{isLang === "en" ? "Categories" : " الاقسام:"}</label>

            <MultiSelect
              optionLabel="name"
              filter
              placeholder="Select categories"
              display="chip"
              id="categories"
              value={formData.categories}
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={categoriesData}
              onChange={e => setFormData({ ...formData, categories: e.target.value })}
            />
          </div>
        </div>

        <div className="d-flex gap-4 mb-3">
          <div className="w-100">
            <label htmlFor="stores">{isLang === "en" ? "Stores" : " المتاجر:"}</label>
            <MultiSelect
              optionLabel="name"
              display="chip"
              filter
              placeholder="Select stores"
              id="stores"
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={storesData}
              value={formData.stores}
              onChange={e => setFormData({ ...formData, stores: e.target.value })}
            />
          </div>
          <div className="w-100">
            <label htmlFor="stores">{isLang === "en" ? "brands" : " الماركات:"}</label>
            <MultiSelect
              optionLabel="name"
              filter
              display="chip"
              placeholder="Select brands"
              id="brands"
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              options={brandsData}
              value={formData.brands}
              onChange={e => setFormData({ ...formData, brands: e.target.value })}
            />
          </div>
        </div>

        <div className="d-flex gap-4 mb-3">
          <div className="w-100">
            <label htmlFor="insects">{isLang === "en" ? "Insects" : " الحشرات:"}</label>
            <MultiSelect
              optionLabel="name"
              id="insects"
              value={formData.insects}
              onChange={e => setFormData({ ...formData, insects: e.target.value })}
              filter
              placeholder="Select Insects"
              maxSelectedLabels={3}
              className="w-100 md:w-20rem"
              display="chip"
              options={insectsData}
            />
          </div>
        </div>

        <div className="images-card card p-4 my-4 pt-4 mb-4">
          <div className="d-flex justify-content-between mb-5">
            <h4 for="ingredients"> {isLang === "en" ? "Ingredients Image:" : " صورة المكونات:"}</h4>
            <input type="file" name="ingredients" onChange={handleIngredientsImageChange} />
          </div>
          <div className="d-flex justify-content-between mb-5">
            <h4 for="nutrition"> {isLang === "en" ? "Nutrition Image:" : " صورة التغذية:"}</h4>

            <input type="file" name="nutrition" onChange={handleNutritionFactsImageChange} />
          </div>

          <div className="d-flex justify-content-between mb-5">
            <h4 for="packaging">{isLang === "en" ? "Packaging Image:" : " صورة التغليف:"}</h4>

            <input type="file" name="packaging" onChange={handlePackagingImageChange} />
          </div>

          <div className="d-flex justify-content-between mb-5">
            <h4 for="interested">{isLang === "en" ? "More Image:" : " صورة اضافية:"}</h4>

            <input type="file" name="interested" onChange={handleMoreInterestingImageChange} />
          </div>
        </div>

        <div className="d-flex gap-3 justify-content-start ">
          <Button
            className="mt-4 d-flex justify-content-center align-items-center"
            type="submit"
            label={isLang === "en" ? "Add Product" : "إضافة المنتج"}
            icon="pi pi-check"
          />
          <Link to="/products">
            <Button
              className="mt-4 d-flex justify-content-center align-items-center"
              type="submit"
              label={isLang === "en" ? "Cancel" : "إلغاء"}
            />
          </Link>
        </div>
      </div>
      <div className="col-md-3 px-3 card right-card pt-5 ">
        <div className="product-status d-flex justify-content-around">
          <input
            type="radio"
            className="btn-check"
            name="status"
            id="undefined-outlined"
            value="undefined"
            checked={status === "undefined"}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-warning status-label" htmlFor="undefined-outlined">
            {isLang === "en" ? "Undefined" : "غير معرف"}
          </label>

          <input
            type="radio"
            className="btn-check"
            name="status"
            id="safe-outlined"
            value="safe"
            checked={status === "safe"}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-success status-label" htmlFor="safe-outlined">
            {isLang === "en" ? "Safe" : " امن"}
          </label>

          <input
            type="radio"
            className="btn-check"
            name="status"
            id="unsafe-outlined"
            value="unsafe"
            checked={status === "unsafe"}
            onChange={handleStatusChange}
            autoComplete="off"
          />
          <label className="btn btn-outline-danger status-label" htmlFor="unsafe-outlined">
            {isLang === "en" ? "Unsafe" : " غير امن"}
          </label>
        </div>
        <div className="card p-3 pb-3 justify-content-between mt-3 d-flex flex-row align-items-center">
          <h6 className="ml-2">{isLang === "en" ? " Top 10 products " : " اخر 10 منتجات"}</h6>
          <Checkbox
            name="category"
            value={formData?.ranking}
            onChange={handleRankingChange}
            checked={formData?.ranking !== null}
          />
        </div>
        <div className="pt-3">
          <div className="card p-2 mb-4 front-image">
            <h4 className="mb-2">{isLang === "en" ? " Main Image: " : " الصورة الاساسية:  "} </h4>
            <input type="file" className="mb-3" onChange={handleFrontImageChange} />
            {formData.front && (
              <div>
                <Image src={URL.createObjectURL(formData.front)} width="90px" height="100px" preview />
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ProductForm;
